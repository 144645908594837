import React from "react"
import { getHighlightedString } from "./logic"

const SearchLabel = ({ searchString, searchResult, link, loading }) => {
  return (
    <>
      {searchResult && searchResult.length > 0 ? (
        <label htmlFor="search-input" className={ loading ? 'loading' : ''}>
          <ul style={{ listStyle: "none", padding: 0 }}>
            {searchResult.map(item => (
              <li key={item.description}>{getHighlightedString(searchString, item, link)}</li>
            ))}
          </ul>
        </label>
      ) : null}
    </>
  )
}

export default SearchLabel
