import _ from "lodash"
import { useEffect, useState } from "react"
import { Spinner } from "reactstrap"
import { getSearchResultsAsync } from "./logic"
import SearchLabel from "./search-label"
import React from 'react';

function SucheIndependent({
  data,
  handleSearchResult,
  showResults,
  showAll,
  placeholder,
  link,
  initialSearch,
}) {
  const [searchString, setSearchString] = useState("")
  const [searchResult, setSearchResult] = useState([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    updateResult()
  }, [searchString])

  useEffect(() => {
    if (!showResults) {
      handleSearchResult(searchResult, searchString)
    }
  }, [searchResult])

  const updateResult = async() => {
    setLoading(true);
    await getSearchResultsAsync(searchString)
      .then(
        response => {
          setSearchResult(response as any)
          setLoading(false);
        },
        () => {
          return []
        }
      )
  }

  const handleInput = _.debounce((val)=> {
    setSearchString(val)
  }, 10);

  return (
    <div>
      <div className="search-box">
        <span className="icon">
          <i className="fas fa-search" aria-hidden="true"></i>
        </span>
        <input
          value={searchString}
          name="search-input"
          type="text"
          onChange={(e) => handleInput(e.target.value)}
          placeholder={placeholder ? placeholder : "Mind. 3 Zeichen eingeben"}
          aria-label="Suche"
          autoComplete="off"
        />

        {showResults ? (
          <SearchLabel
            searchString={searchString}
            searchResult={searchResult}
            link={link}
            loading={loading}
          ></SearchLabel>
        ) : null}

        {loading ? <Spinner type="grow" size="sm" color="info" /> : null}

        {searchString ? (
          <span
            className="del"
            onClick={() => {
              setSearchString("")
            }}
          >
            <i className="fas fa-times" aria-hidden="true"></i>
          </span>
        ) : null}
      </div>
    </div>
  )
}

export default SucheIndependent
