import { graphql, Link, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import React from "react"
import Helmet from "react-helmet"
import { Col, Container, Row } from "reactstrap"
import AboutNizza from "../components/content/about-nizza"
import Divider from "../components/layout/divider"
import Layout from "../components/layout/layout"
import SucheIndependent from "../components/search/suche-independent"

const IndexPage = ({ pageContext, path }) => {
  const data = useStaticQuery(
    graphql`
      query {
        fileName: file(relativePath: { eq: "covers/hero-index.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 980) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        waren: file(relativePath: { eq: "stock/waren.jpg" }) {
          childImageSharp {
            fixed(width: 130, height: 130) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        dienstleistungen: file(
          relativePath: { eq: "stock/dienstleistungen.jpg" }
        ) {
          childImageSharp {
            fixed(width: 130, height: 130) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <Layout location="/">
      <Helmet>
        <link rel="canonical" href="https://nizza-markenklasse.de" />
      </Helmet>
      <BackgroundImage
        Tag="section"
        fluid={[
          data.fileName.childImageSharp.fluid,
          "linear-gradient(147deg, rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.77))",
        ].reverse()}
        backgroundColor={`#040e18`}
        id="search"
        alt="Home: www.nizza-markenklasse.de"
      >
        <Container>
          <Row className="align-items-center">
            <Col>
              <h2>Suche dein Produkt</h2>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col style={{ margin: "4px 0 14px 0" }}>
              <h1 style={{ lineHeight: 1.5, fontWeight: 400 }}>
                Wir finden die passende Nizza-Klasse für<br></br> deine
                Markenanmeldung
              </h1>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col>
              <SucheIndependent
                showAll={false}
                showResults={true}
                link={true}
              ></SucheIndependent>
            </Col>
          </Row>
          <Row className="">
            <Col style={{ textAlign: "right" }}>
              <small>
                Quelle:{" "}
                <a href="#disclaimer" target="_self" title="Hinweis">
                  DPMA
                </a>{" "}
                (Stand März 2020)
              </small>
            </Col>
          </Row>
        </Container>
      </BackgroundImage>
      <Container fluid={true} tag="section" id="types">
        <Container>
          <Row className="product-type align-items-center">
            <Col className="ware align-center" md="6">
              <Row className="align-items-center justify-content-center">
                <Col xs="auto" style={{ paddingRight: "14px" }}>
                  {" "}
                  <Link to="/produkte" title="Zu den Klassen 1-34">
                    <Img
                      fixed={data.waren.childImageSharp.fixed}
                      className="category-image"
                      alt="Nizza-Klasse 1-34: Physikalische Produkte"
                      title="Nizza-Klasse 1-34"
                    ></Img>
                  </Link>
                </Col>
                <Col xs="auto" style={{ paddingLeft: 0 }}>
                  <Link to="/produkte" title="Zu den Klassen 1-34">
                    <h3>Physische Produkte</h3>
                  </Link>
                  <p>Beispiele: T-Shirt, Wasserball</p>
                  <small>
                    <Link to="/produkte" title="Zu den Klassen 1-34">
                      Zu den Klassen 1-34
                    </Link>
                  </small>
                </Col>
              </Row>
            </Col>
            <Col className="dienst align-center" md="6">
              <Row className="align-items-center justify-content-center">
                <Col xs="auto" style={{ paddingRight: "14px" }}>
                  {" "}
                  <Link to="/dienstleistungen" title="Zu den Klassen 35-45">
                    <Img
                      fixed={data.dienstleistungen.childImageSharp.fixed}
                      className="category-image"
                      alt="Nizza-Klasse 35-45: Dienstleistungen"
                      title="Nizza-Klasse 35-45"
                    ></Img>
                  </Link>
                </Col>
                <Col xs="auto" style={{ paddingLeft: 0 }}>
                  <Link to="/dienstleistungen" title="Zu den Klassen 35-45">
                    <h3>Dienstleistungen</h3>
                  </Link>
                  <p>Beispiele: Beratung, Marketing</p>
                  <small>
                    <Link to="/dienstleistungen" title="Zu den Klassen 35-45">
                      Zu den Klassen 35-45
                    </Link>
                  </small>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>

      <Divider fluid={false}></Divider>
      
      <AboutNizza></AboutNizza>
    </Layout>
  )
}

export default IndexPage
